<template>
  <div>
    <el-row type="flex" justify="space-between">
      <el-button>{{ temp.isReprint === 0 ? "原创" : "转载" }}</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </el-row>
    <div class="bgFFF">
      <el-form label-width="150px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="资讯 ID:">
              {{ temp.informationId }}
            </el-form-item>
            <el-form-item label="状态:">
              {{ temp.status | statusFilter }}
            </el-form-item>
            <el-form-item label-width="0" style="width: 500px">
              <el-carousel height="200px" style="text-align: center">
                <el-carousel-item v-for="item in temp.infoPreviewImgList" :key="item">
                  <img :src="item" style="height: 200px" />
                </el-carousel-item>
              </el-carousel>
            </el-form-item>
            <el-form-item label="标题:">
              {{ temp.title }}
            </el-form-item>
            <el-form-item label="简介:">
              {{ temp.introduction }}
            </el-form-item>
            <el-form-item label="作者:">
              {{ temp.author }}
            </el-form-item>
            <el-form-item label="分类:">
              {{ temp.infoCateFullName }}
            </el-form-item>
            <el-form-item label="区域:">
              {{ temp.publishCityStr }}
            </el-form-item>
            <el-form-item label="是否置顶:">
              {{ temp.isStick | isStickFilter }}
            </el-form-item>
            <el-form-item label="发布渠道:">
              {{ temp.releaseTypeStr }}
            </el-form-item>
            <el-form-item>
              <div slot="label">阅读数（{{ temp.readNumber }}）:</div>
              基础值（{{ temp.readBasics }}）+实际用户操作值（{{ temp.userRead }}）*放大倍数（{{
                temp.readMultiple
              }}）
            </el-form-item>
            <el-form-item>
              <div slot="label">点赞数（{{ temp.pointNumber }}）:</div>
              基础值（{{ temp.pointBasics }}）+实际用户操作值（{{ temp.userPoint }}）*放大倍数（{{
                temp.pointMultiple
              }}）
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="定时上架时间:">
              {{ temp.upperTime }}
            </el-form-item>
            <el-form-item label="定时下架时间:">
              {{ temp.lowerTime }}
            </el-form-item>
            <el-form-item label="分享标题:">
              {{ temp.shareTitle }}
            </el-form-item>
            <el-form-item label="分享描述:">
              {{ temp.shareDescribe }}
            </el-form-item>
            <el-form-item label="分享缩略图:">
              <img :src="temp.shareImgUrl" style="height: 100px" />
            </el-form-item>
            <el-form-item label="预览链接:">
              <div>{{ baseUrl }}?id={{ informationId }}</div>
            </el-form-item>
            <el-form-item label="最后操作人:">
              {{ temp.updateUserName }}
            </el-form-item>
            <el-form-item label="最后修改时间:">
              {{ temp.updateTime }}
            </el-form-item>
            <el-form-item label="初始创建时间:">
              {{ temp.createTime }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    isStickFilter(val) {
      const arr = ["否", "是"];
      return arr[val];
    },
    statusFilter(val) {
      const arr = ["已保存", "待发布", "已上架", "已下架", "", "已封存"];
      return arr[val];
    },
  },
  data() {
    return {
      informationId: this.$route.query.informationId,
      temp: {
        isReprint: "",
        informationId: "",
        status: "",
        infoPreviewImgList: [],
        title: "",
        introduction: "",
        author: "",
        infoCateFullName: "",
        publishCityStr: "",
        isStick: "",
        releaseTypeStr: "",
        readNumber: "",
        readBasics: "",
        userRead: "",
        readMultiple: "",
        pointNumber: "",
        pointBasics: "",
        userPoint: "",
        pointMultiple: "",
        upperTime: "",
        lowerTime: "",
        shareTitle: "",
        shareDescribe: "",
        updateTime: "",
        updateUser: "",
        updateUserName: "",
        createTime: "",
      },
      baseUrl: "",
    };
  },
  mounted() {
    this.getConfigMap();
    this.getInfoDetail();
  },
  methods: {
    getConfigMap() {
      const opt = {
        url: "/acb/2.0/sysConfig/getConfigMap",
        method: "get",
        data: {},
        success: (res) => {
          console.log(res);
          this.baseUrl = res.value.appUrl + "/info.html";
        },
      };
      this.$request(opt);
    },
    // 获取详情
    getInfoDetail() {
      const opt = {
        url: "/acb/2.0/information/detail?informationId=" + this.informationId,
        method: "get",
        data: {},
        success: (res) => {
          this.temp.isReprint = res.value.isReprint;
          this.temp.informationId = res.value.informationId;
          this.temp.status = res.value.status;
          this.temp.infoPreviewImgList = res.value.infoPreviewImgList;
          this.temp.title = res.value.title;
          this.temp.introduction = res.value.introduction;
          this.temp.author = res.value.author;
          this.temp.infoCateFullName = res.value.infoCateFullName;
          this.temp.publishCityStr = res.value.publishCityStr;
          this.temp.isStick = res.value.isStick;
          this.temp.releaseTypeStr = res.value.releaseTypeStr;
          this.temp.readNumber = res.value.readNumber;
          this.temp.readBasics = res.value.readBasics;
          this.temp.userRead = res.value.userRead;
          this.temp.readMultiple = res.value.readMultiple;
          this.temp.pointNumber = res.value.pointNumber;
          this.temp.pointBasics = res.value.pointBasics;
          this.temp.userPoint = res.value.userPoint;
          this.temp.pointMultiple = res.value.pointMultiple;
          this.temp.upperTime = res.value.upperTime;
          this.temp.lowerTime = res.value.lowerTime;
          this.temp.shareTitle = res.value.shareTitle;
          this.temp.shareDescribe = res.value.shareDescribe;
          this.temp.shareImgUrl = res.value.shareImgUrl;
          this.temp.updateTime = res.value.updateTime;
          this.temp.updateUser = res.value.updateUser;
          this.temp.createTime = res.value.createTime;
          this.temp.updateUserName = res.value.updateUserName;
        },
      };
      this.$request(opt);
    },
  },
};
</script>

<style lang="stylus" scoped>
.colorC
  color #1C6FFF
.right-con
  width 130px
  height 130px
  background-size 100% 100%
  background-size cover
>>>.el-form-item__content
  word-break break-all
</style>
